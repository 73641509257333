
jQuery(function () {
  initMobileNav();
  initBgImg();
  initAccordion();
  initOpenClose();
});

//mobile menu init
function initMobileNav() {
  jQuery('body').mobileNav({
    menuActiveClass: 'nav-active',
    menuOpener: '.js-nav-opener',
    hideOnClickOutside: true,
    menuDrop: '.nav__drop',
  });
}

// accordion menu init
function initAccordion() {
  jQuery('.accordion').slideAccordion({
    opener: '.js-accordion-opener',
    slider: '.accordion__slide',
    animSpeed: 300,
  });
}

// open-close init
function initOpenClose() {
  jQuery('.open-close').openClose({
    activeClass: 'active',
    opener: '.js-open-close-opener',
    slider: '.js-open-close-slide',
    animSpeed: 400,
    hideOnClickOutside: true,
    effect: 'slide',
  });
}

function initBgImg() {
  jQuery('.page-banner__img-wrap:has(img)').each(function () {
    var imgHolder = jQuery(this);
    imgHolder.css({
      'background-image': 'url(' + imgHolder.find('img').attr('src') + ')',
    });
  });
}

/*
 * Simple Mobile Navigation
 */
(function ($) {
  function MobileNav(options) {
    this.options = $.extend(
      {
        container: null,
        hideOnClickOutside: false,
        menuActiveClass: 'nav-active',
        menuOpener: '.nav-opener',
        menuDrop: '.nav-drop',
        toggleEvent: 'click',
        outsideClickEvent: 'click touchstart pointerdown MSPointerDown',
      },
      options
    );
    this.initStructure();
    this.attachEvents();
  }
  MobileNav.prototype = {
    initStructure: function () {
      this.page = $('html');
      this.container = $(this.options.container);
      this.opener = this.container.find(this.options.menuOpener);
      this.drop = this.container.find(this.options.menuDrop);
    },
    attachEvents: function () {
      var self = this;

      if (activateResizeHandler) {
        activateResizeHandler();
        activateResizeHandler = null;
      }

      this.outsideClickHandler = function (e) {
        if (self.isOpened()) {
          var target = $(e.target);
          if (!target.closest(self.opener).length && !target.closest(self.drop).length) {
            self.hide();
          }
        }
      };

      this.openerClickHandler = function (e) {
        e.preventDefault();
        self.toggle();
      };

      this.opener.on(this.options.toggleEvent, this.openerClickHandler);
    },
    isOpened: function () {
      return this.container.hasClass(this.options.menuActiveClass);
    },
    show: function () {
      this.container.addClass(this.options.menuActiveClass);
      if (this.options.hideOnClickOutside) {
        this.page.on(this.options.outsideClickEvent, this.outsideClickHandler);
      }
    },
    hide: function () {
      this.container.removeClass(this.options.menuActiveClass);
      if (this.options.hideOnClickOutside) {
        this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
      }
    },
    toggle: function () {
      if (this.isOpened()) {
        this.hide();
      } else {
        this.show();
      }
    },
    destroy: function () {
      this.container.removeClass(this.options.menuActiveClass);
      this.opener.off(this.options.toggleEvent, this.clickHandler);
      this.page.off(this.options.outsideClickEvent, this.outsideClickHandler);
    },
  };

  var activateResizeHandler = function () {
    var win = $(window),
      doc = $('html'),
      resizeClass = 'resize-active',
      flag,
      timer;
    var removeClassHandler = function () {
      flag = false;
      doc.removeClass(resizeClass);
    };
    var resizeHandler = function () {
      if (!flag) {
        flag = true;
        doc.addClass(resizeClass);
      }
      clearTimeout(timer);
      timer = setTimeout(removeClassHandler, 500);
    };
    win.on('resize orientationchange', resizeHandler);
  };

  $.fn.mobileNav = function (opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];

    return this.each(function () {
      var $container = jQuery(this);
      var instance = $container.data('MobileNav');

      if (typeof opt === 'object' || typeof opt === 'undefined') {
        $container.data(
          'MobileNav',
          new MobileNav(
            $.extend(
              {
                container: this,
              },
              opt
            )
          )
        );
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };
})(jQuery);

/*
 * jQuery Open/Close plugin
 */
(function ($) {
  function OpenClose(options) {
    this.options = $.extend(
      {
        addClassBeforeAnimation: true,
        hideOnClickOutside: false,
        activeClass: 'active',
        opener: '.opener',
        slider: '.slide',
        animSpeed: 400,
        effect: 'fade',
        event: 'click',
      },
      options
    );
    this.init();
  }
  OpenClose.prototype = {
    init: function () {
      if (this.options.holder) {
        this.findElements();
        this.attachEvents();
        this.makeCallback('onInit', this);
      }
    },
    findElements: function () {
      this.holder = $(this.options.holder);
      this.opener = this.holder.find(this.options.opener);
      this.slider = this.holder.find(this.options.slider);
    },
    attachEvents: function () {
      // add handler
      var self = this;
      this.eventHandler = function (e) {
        e.preventDefault();
        if (self.slider.hasClass(slideHiddenClass)) {
          self.showSlide();
        } else {
          self.hideSlide();
        }
      };
      self.opener.on(self.options.event, this.eventHandler);

      // hover mode handler
      if (self.options.event === 'hover') {
        self.opener.on('mouseenter', function () {
          if (!self.holder.hasClass(self.options.activeClass)) {
            self.showSlide();
          }
        });
        self.holder.on('mouseleave', function () {
          self.hideSlide();
        });
      }

      // outside click handler
      self.outsideClickHandler = function (e) {
        if (self.options.hideOnClickOutside) {
          var target = $(e.target);
          if (!target.is(self.holder) && !target.closest(self.holder).length) {
            self.hideSlide();
          }
        }
      };

      // set initial styles
      if (this.holder.hasClass(this.options.activeClass)) {
        $(document).on('click touchstart', self.outsideClickHandler);
      } else {
        this.slider.addClass(slideHiddenClass);
      }
    },
    showSlide: function () {
      var self = this;
      if (self.options.addClassBeforeAnimation) {
        self.holder.addClass(self.options.activeClass);
      }
      self.slider.removeClass(slideHiddenClass);
      $(document).on('click touchstart', self.outsideClickHandler);

      self.makeCallback('animStart', true);
      toggleEffects[self.options.effect].show({
        box: self.slider,
        speed: self.options.animSpeed,
        complete: function () {
          if (!self.options.addClassBeforeAnimation) {
            self.holder.addClass(self.options.activeClass);
          }
          self.makeCallback('animEnd', true);
        },
      });
    },
    hideSlide: function () {
      var self = this;
      if (self.options.addClassBeforeAnimation) {
        self.holder.removeClass(self.options.activeClass);
      }
      $(document).off('click touchstart', self.outsideClickHandler);

      self.makeCallback('animStart', false);
      toggleEffects[self.options.effect].hide({
        box: self.slider,
        speed: self.options.animSpeed,
        complete: function () {
          if (!self.options.addClassBeforeAnimation) {
            self.holder.removeClass(self.options.activeClass);
          }
          self.slider.addClass(slideHiddenClass);
          self.makeCallback('animEnd', false);
        },
      });
    },
    destroy: function () {
      this.slider.removeClass(slideHiddenClass).css({
        display: '',
      });
      this.opener.off(this.options.event, this.eventHandler);
      this.holder.removeClass(this.options.activeClass).removeData('OpenClose');
      $(document).off('click touchstart', this.outsideClickHandler);
    },
    makeCallback: function (name) {
      if (typeof this.options[name] === 'function') {
        var args = Array.prototype.slice.call(arguments);
        args.shift();
        this.options[name].apply(this, args);
      }
    },
  };

  // add stylesheet for slide on DOMReady
  var slideHiddenClass = 'js-slide-hidden';
  (function () {
    var tabStyleSheet = $('<style type="text/css">')[0];
    var tabStyleRule = '.' + slideHiddenClass;
    tabStyleRule += '{position:absolute !important;left:-9999px !important;top:-9999px !important;display:block !important}';
    if (tabStyleSheet.styleSheet) {
      tabStyleSheet.styleSheet.cssText = tabStyleRule;
    } else {
      tabStyleSheet.appendChild(document.createTextNode(tabStyleRule));
    }
    $('head').append(tabStyleSheet);
  })();

  // animation effects
  var toggleEffects = {
    slide: {
      show: function (o) {
        o.box.stop(true).hide().slideDown(o.speed, o.complete);
      },
      hide: function (o) {
        o.box.stop(true).slideUp(o.speed, o.complete);
      },
    },
    fade: {
      show: function (o) {
        o.box.stop(true).hide().fadeIn(o.speed, o.complete);
      },
      hide: function (o) {
        o.box.stop(true).fadeOut(o.speed, o.complete);
      },
    },
    none: {
      show: function (o) {
        o.box.hide().show(0, o.complete);
      },
      hide: function (o) {
        o.box.hide(0, o.complete);
      },
    },
  };

  // jQuery plugin interface
  $.fn.openClose = function (opt) {
    var args = Array.prototype.slice.call(arguments);
    var method = args[0];

    return this.each(function () {
      var $holder = jQuery(this);
      var instance = $holder.data('OpenClose');

      if (typeof opt === 'object' || typeof opt === 'undefined') {
        $holder.data(
          'OpenClose',
          new OpenClose(
            $.extend(
              {
                holder: this,
              },
              opt
            )
          )
        );
      } else if (typeof method === 'string' && instance) {
        if (typeof instance[method] === 'function') {
          args.shift();
          instance[method].apply(instance, args);
        }
      }
    });
  };
})(jQuery);

/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-backgroundblendmode-setclasses !*/
!(function (e, n, t) {
  function r(e) {
    var n = C.className,
      t = Modernizr._config.classPrefix || '';
    if ((S && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var r = new RegExp('(^|\\s)' + t + 'no-js(\\s|$)');
      n = n.replace(r, '$1' + t + 'js$2');
    }
    Modernizr._config.enableClasses && ((n += ' ' + t + e.join(' ' + t)), S ? (C.className.baseVal = n) : (C.className = n));
  }
  function o(e, n) {
    return typeof e === n;
  }
  function i() {
    var e, n, t, r, i, s, a;
    for (var l in x)
      if (x.hasOwnProperty(l)) {
        if (((e = []), (n = x[l]), n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length))) for (t = 0; t < n.options.aliases.length; t++) e.push(n.options.aliases[t].toLowerCase());
        for (r = o(n.fn, 'function') ? n.fn() : n.fn, i = 0; i < e.length; i++) (s = e[i]), (a = s.split('.')), 1 === a.length ? (Modernizr[a[0]] = r) : (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), (Modernizr[a[0]][a[1]] = r)), h.push((r ? '' : 'no-') + a.join('-'));
      }
  }
  function s(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function (e, n, t) {
        return n + t.toUpperCase();
      })
      .replace(/^-/, '');
  }
  function a(e, n) {
    return !!~('' + e).indexOf(n);
  }
  function l() {
    return 'function' != typeof n.createElement ? n.createElement(arguments[0]) : S ? n.createElementNS.call(n, 'http://www.w3.org/2000/svg', arguments[0]) : n.createElement.apply(n, arguments);
  }
  function f(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }
  function u(e, n, t) {
    var r;
    for (var i in e) if (e[i] in n) return t === !1 ? e[i] : ((r = n[e[i]]), o(r, 'function') ? f(r, t || n) : r);
    return !1;
  }
  function d(e) {
    return e
      .replace(/([A-Z])/g, function (e, n) {
        return '-' + n.toLowerCase();
      })
      .replace(/^ms-/, '-ms-');
  }
  function p(n, t, r) {
    var o;
    if ('getComputedStyle' in e) {
      o = getComputedStyle.call(e, n, t);
      var i = e.console;
      if (null !== o) r && (o = o.getPropertyValue(r));
      else if (i) {
        var s = i.error ? 'error' : 'log';
        i[s].call(i, 'getComputedStyle returning null, its possible modernizr test results are inaccurate');
      }
    } else o = !t && n.currentStyle && n.currentStyle[r];
    return o;
  }
  function c() {
    var e = n.body;
    return e || ((e = l(S ? 'svg' : 'body')), (e.fake = !0)), e;
  }
  function m(e, t, r, o) {
    var i,
      s,
      a,
      f,
      u = 'modernizr',
      d = l('div'),
      p = c();
    if (parseInt(r, 10)) for (; r--; ) (a = l('div')), (a.id = o ? o[r] : u + (r + 1)), d.appendChild(a);
    return (i = l('style')), (i.type = 'text/css'), (i.id = 's' + u), (p.fake ? p : d).appendChild(i), p.appendChild(d), i.styleSheet ? (i.styleSheet.cssText = e) : i.appendChild(n.createTextNode(e)), (d.id = u), p.fake && ((p.style.background = ''), (p.style.overflow = 'hidden'), (f = C.style.overflow), (C.style.overflow = 'hidden'), C.appendChild(p)), (s = t(d, e)), p.fake ? (p.parentNode.removeChild(p), (C.style.overflow = f), C.offsetHeight) : d.parentNode.removeChild(d), !!s;
  }
  function v(n, r) {
    var o = n.length;
    if ('CSS' in e && 'supports' in e.CSS) {
      for (; o--; ) if (e.CSS.supports(d(n[o]), r)) return !0;
      return !1;
    }
    if ('CSSSupportsRule' in e) {
      for (var i = []; o--; ) i.push('(' + d(n[o]) + ':' + r + ')');
      return (
        (i = i.join(' or ')),
        m('@supports (' + i + ') { #modernizr { position: absolute; } }', function (e) {
          return 'absolute' == p(e, null, 'position');
        })
      );
    }
    return t;
  }
  function g(e, n, r, i) {
    function f() {
      d && (delete N.style, delete N.modElem);
    }
    if (((i = o(i, 'undefined') ? !1 : i), !o(r, 'undefined'))) {
      var u = v(e, r);
      if (!o(u, 'undefined')) return u;
    }
    for (var d, p, c, m, g, y = ['modernizr', 'tspan', 'samp']; !N.style && y.length; ) (d = !0), (N.modElem = l(y.shift())), (N.style = N.modElem.style);
    for (c = e.length, p = 0; c > p; p++)
      if (((m = e[p]), (g = N.style[m]), a(m, '-') && (m = s(m)), N.style[m] !== t)) {
        if (i || o(r, 'undefined')) return f(), 'pfx' == n ? m : !0;
        try {
          N.style[m] = r;
        } catch (h) {}
        if (N.style[m] != g) return f(), 'pfx' == n ? m : !0;
      }
    return f(), !1;
  }
  function y(e, n, t, r, i) {
    var s = e.charAt(0).toUpperCase() + e.slice(1),
      a = (e + ' ' + b.join(s + ' ') + s).split(' ');
    return o(n, 'string') || o(n, 'undefined') ? g(a, n, r, i) : ((a = (e + ' ' + z.join(s + ' ') + s).split(' ')), u(a, n, t));
  }
  var h = [],
    C = n.documentElement,
    S = 'svg' === C.nodeName.toLowerCase(),
    x = [],
    _ = {
      _version: '3.6.0',
      _config: { classPrefix: '', enableClasses: !0, enableJSClass: !0, usePrefixes: !0 },
      _q: [],
      on: function (e, n) {
        var t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function (e, n, t) {
        x.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function (e) {
        x.push({ name: null, fn: e });
      },
    },
    Modernizr = function () {};
  (Modernizr.prototype = _), (Modernizr = new Modernizr());
  var w = 'Moz O ms Webkit',
    b = _._config.usePrefixes ? w.split(' ') : [];
  _._cssomPrefixes = b;
  var E = function (n) {
    var r,
      o = prefixes.length,
      i = e.CSSRule;
    if ('undefined' == typeof i) return t;
    if (!n) return !1;
    if (((n = n.replace(/^@/, '')), (r = n.replace(/-/g, '_').toUpperCase() + '_RULE'), r in i)) return '@' + n;
    for (var s = 0; o > s; s++) {
      var a = prefixes[s],
        l = a.toUpperCase() + '_' + r;
      if (l in i) return '@-' + a.toLowerCase() + '-' + n;
    }
    return !1;
  };
  _.atRule = E;
  var z = _._config.usePrefixes ? w.toLowerCase().split(' ') : [];
  _._domPrefixes = z;
  var P = { elem: l('modernizr') };
  Modernizr._q.push(function () {
    delete P.elem;
  });
  var N = { style: P.elem.style };
  Modernizr._q.unshift(function () {
    delete N.style;
  }),
    (_.testAllProps = y);
  var k = (_.prefixed = function (e, n, t) {
    return 0 === e.indexOf('@') ? E(e) : (-1 != e.indexOf('-') && (e = s(e)), n ? y(e, n, t) : y(e, 'pfx'));
  });
  Modernizr.addTest('backgroundblendmode', k('backgroundBlendMode', 'text')), i(), r(h), delete _.addTest, delete _.addAsyncTest;
  for (var T = 0; T < Modernizr._q.length; T++) Modernizr._q[T]();
  e.Modernizr = Modernizr;
})(window, document);
